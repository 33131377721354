import { twMerge } from 'tailwind-merge';

/**
 * Container for handling responsiveness of main content that especially has a sidebar (e.g. dashboard)
 * and container for handling detail
 */
export default function ContainerMain({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'mx-auto flex w-full max-w-7xl flex-1 flex-col px-4 py-4 sm:px-6 lg:px-8',
        className
      )}
    >
      {children}
    </div>
  );
}
