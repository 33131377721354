export const getBaseUrlCompany = () => {
  const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';
  const isStaging = import.meta.env.VITE_NODE_ENV === 'staging';

  if (isDevelopment) return 'https://dev-mobile.redikru.com';
  if (isStaging) return 'https://stg-company.redikru.com';

  return 'https://talent.redikru.com';
};

export const getBaseUrlCertification = () => {
  const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';
  const isStaging = import.meta.env.VITE_NODE_ENV === 'staging';

  if (isDevelopment) return 'https://dev-order.redikru.com';
  if (isStaging) return 'https://dev-order.redikru.com';

  return 'https://order.redikru.com';
};
