import { QueryDefinition } from '@reduxjs/toolkit/dist/query/react';

export const rtkInfiniteScrollConfig: Partial<QueryDefinition<any, any, any, any>> = {
  merge(currentCacheData, returnData, otherData) {
    if (returnData.pagination?.page === 1 || otherData.arg?.page === 1) {
      const cache = currentCacheData;
      cache.list = [...(returnData.list || [])];
      cache.pagination = returnData.pagination;
      return cache;
    }
    if (
      currentCacheData.list &&
      currentCacheData.list.length < returnData.pagination.totalItems &&
      currentCacheData.pagination.page !== returnData.pagination.page
    ) {
      const cache = currentCacheData;
      cache.list?.push(...(returnData.list || []));
      cache.pagination = returnData.pagination;
      return cache;
    }
    return currentCacheData;
  },

  forceRefetch({ currentArg, previousArg }) {
    return currentArg !== previousArg;
  },
  serializeQueryArgs: ({ endpointName }) => endpointName,
};

// TODO: Improve this Code
/**
 * This code works for handle pagination caching
 * when delete the data on page > 1 success
 */

// serializeQueryArgs: ({ endpointName }) => {
//   return endpointName;
// },
// // Always merge incoming data to the cache entry
// merge(currentCacheData, returnData, otherData) {
//   const cache = { ...currentCacheData };
//   cache.list = returnData.list || [];
//   cache.pagination = returnData.pagination;
//   return cache;
// },
// forceRefetch({ currentArg, previousArg }) {
//   return currentArg !== previousArg;
// },
