import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { PAGINATION_LIMIT } from '@/constants/pagination';
import { TQuestionType } from '@/constants/question';
import { createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';
import {
  IRequestConfirmRecommendation,
  IResponseJobsForm,
  IRequestJobsForm,
  IRequestParamGetJobs,
  IResponseGetJobDetail,
  IResponseGetJobs,
  IReturnGetJobDetail,
  IReturnGetJobs,
  IReturnJobForm,
  TRequestGetOfferStatusList,
  TReturnGetOfferStatusList,
} from './type';
import { IDataDropdown } from '@/interfaces/dropdown';

import { TCrewProcessedStatus } from '../talentHub/type';

export const jobsApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'jobsApi',
  tagTypes: ['JOBS', 'CURRENCY', 'GET_OFFER_STATUS'],
  endpoints: builder => ({
    getJobs: builder.query<IReturnGetJobs, IRequestParamGetJobs>({
      query: ({ page, isDraft, isOpen, rank, keyword, vesselType, date }) => ({
        url: '/v1/company/job',
        method: 'GET',
        params: {
          page,
          'is-draft': isDraft,
          'is-open': isOpen,
          rank: rank,
          keyword: keyword,
          'vessel-type': vesselType,
          limit: PAGINATION_LIMIT,
          date,
        },
      }),
      providesTags: ['JOBS'],
      transformResponse: (response: IResponseGetJobs) => {
        return {
          pagination: response.meta,
          list: response.items.map(el => ({
            ...el,
            createdAt: format(new Date(el.createdAt), 'dd-MM-yyyy'),
          })),
        };
      },
    }),

    getJobDetail: builder.query<IReturnGetJobDetail, string | undefined>({
      providesTags: ['JOBS'],
      query: (id?: string) => ({
        url: `/v1/company/job/${id}`,
        method: 'GET',
      }),
    }),

    getJobInitialFormData: builder.query<IReturnJobForm, string>({
      providesTags: ['JOBS'],
      query: (id: string) => ({
        url: `/v1/company/job/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: IResponseGetJobDetail) => {
        return {
          id: response.id || '',
          title: response.title || '',
          description: response.description || '',
          vesselFlag: response.vesselFlag || '',
          vesselType: response?.vesselType?.id || '',
          totalCrew: response.neededCrewNumber || 0,
          contractDuration: {
            year: response?.contractDuration?.year || 0,
            month: response?.contractDuration?.month || 0,
          },
          showSalary: String(response.isShowSalary),
          mandatoryCertificates: response.mandatoryCertificates || [],
          optionalCertificates: response.optionalCertificates || [],
          mandatoryDocuments: response.mandatoryDocuments || [],
          optionalDocuments: response.optionalDocuments || [],
          rank: response.rank.id || '',
          isOpen: response.isOpen,
          isRotational: String(response.isRotational),
          experiencePreference: {
            month: response?.preferredExperience?.month || 0,
            year: response?.preferredExperience?.year || 0,
          },
          isDraft: response.isDraft,
          salaryCurrency: response.salaryCurrency,
          salaryRange: {
            min: response.minSalary || 0,
            max: response.maxSalary || 0,
          },

          questions:
            response?.questions?.map(el => ({
              id: el.id,
              question: el.question,
              type: el.type as TQuestionType,
              options:
                el.choices?.map(el => ({
                  id: el.id,
                  answer: el.label,
                })) || [],
            })) || [],
          isRecommendationEnabled: response.isRecommendationEnabled,
          deadline: response.deadline,
          grt: response.grt,
        };
      },
    }),

    getMasterDataCurrency: builder.query<IDataDropdown[], void>({
      query: () => ({
        url: 'v1/master-data/currency',
        method: 'GET',
      }),
      transformResponse: (data: IDataDropdown[]) =>
        data.map(response => ({
          value: response.value,
          label: response.value,
          keyword: response.label,
        })),
      providesTags: ['CURRENCY'],
    }),

    getOfferStatus: builder.query<{ data: TReturnGetOfferStatusList }, TRequestGetOfferStatusList>({
      providesTags: ['GET_OFFER_STATUS'],
      query: payload => {
        return {
          url: `/v1/master-data/offer-status`,
          method: 'GET',
          params: {
            isActiveOffer: payload.isActiveOffer,
          },
        };
      },
    }),

    /**
     * POST
     */
    createJob: builder.mutation<IResponseJobsForm, IRequestJobsForm>({
      query: job => ({
        url: '/v1/company/job',
        method: 'POST',
        body: job,
      }),
      invalidatesTags: ['JOBS'],
    }),

    editJob: builder.mutation<void, { job: IRequestJobsForm; id: string }>({
      query: ({ job, id }) => ({
        url: `/v1/company/job/${id}`,
        method: 'PATCH',
        body: job,
      }),
      invalidatesTags: ['JOBS'],
    }),

    closeJob: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/v1/company/close-job/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['JOBS'],
    }),

    // confirmRecommendationCrew: builder.mutation<void, IRequestConfirmRecommendation>({
    //   query: payload => ({
    //     url: `/v1/company/applied-job/${payload.jobId}/crew/${payload.crewId}/confirm`,
    //     method: 'PATCH',
    //     body: {
    //       action: payload.action,
    //       reasons: payload.reasons,
    //     },
    //   }),
    // }),

    patchConfirmCrew: builder.mutation<
      void,
      {
        jobId: string;
        crewId: string;
        source: 'RECOMMENDATION' | 'JOB_APPLICATION';
        currentStatus: TCrewProcessedStatus | 'RECOMMENDATION';
      }
    >({
      query: ({ jobId, crewId, ...body }) => ({
        url: `/v1/company/job/${jobId}/crew/${crewId}/confirm`,
        method: 'PATCH',
        body,
      }),
    }),

    patchRejectCrew: builder.mutation<
      void,
      {
        jobId: string;
        crewId: string;
        source: 'RECOMMENDATION' | 'JOB_APPLICATION';
        currentStatus: TCrewProcessedStatus | 'RECOMMENDATION';
        rejectReasons: Array<string> | null;
      }
    >({
      query: ({ jobId, crewId, ...body }) => ({
        url: `/v1/company/job/${jobId}/crew/${crewId}/reject`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  // useConfirmRecommendationCrewMutation,
  useCreateJobMutation,
  useEditJobMutation,
  useGetJobsQuery,
  useGetJobDetailQuery,
  useGetJobInitialFormDataQuery,
  useCloseJobMutation,
  useGetMasterDataCurrencyQuery,
  useGetOfferStatusQuery,
  usePatchConfirmCrewMutation,
  usePatchRejectCrewMutation,
} = jobsApi;
