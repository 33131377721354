import i18n from 'i18next';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lowercaseExceptCapslock = (str: string) => {
  const words = str.split(' ');
  const result = [];

  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (word === word.toUpperCase()) {
      result.push(word);
    } else {
      result.push(word.toLowerCase());
    }
  }

  return result.join(' ');
};

export const capitalizeFirstLetterOfEachWord = (str: string) => {
  if (!str) return str;
  const words: string[] = str.toLowerCase().split(' ');
  words.forEach((word, index) => {
    words[index] = word[0].toUpperCase() + word.slice(1);
  });
  return words.join(' ');
};

export const currencyNumberFormatting = (number: number | string, currency?: string) => {
  const locale = i18n.language === 'id' ? 'id-ID' : 'en-US';
  if (number && currency) {
    return `${currency} ${Number(number).toLocaleString(locale, {
      style: 'decimal',
      currency,
      maximumFractionDigits: currency === 'IDR' ? 0 : undefined,
    })}`;
  } else {
    return '-';
  }
};

/**
 * This function is to convert string value to boolean, because some input select use string value instead of boolean
 * @param value string 'true' | 'false'
 * @returns Boolean value True | False
 */
export const convertStringToBoolean = (value: string): boolean => {
  const truthy: string[] = ['true', 'True', '1'];

  return truthy.includes(value);
};

/**
 * Checks if a given string contains only whitespace characters.
 *
 * @param str - The input string to be checked.
 * @param allowNewlines - Whether to allow newline characters in the string.
 * @returns True if the string contains only whitespace characters, false otherwise.
 */
export const containsOnlyWhitespace = (str: string, allowNewlines: boolean = false): boolean => {
  const regex = allowNewlines ? /^\s*$/ : /^\s+$/;
  return regex.test(str);
};

/**
 * Cleans a string by replacing all consecutive whitespace characters with a single space.
 *
 * @param text The string to be cleaned.
 * @returns The cleaned string with only single spaces between words.
 */
export const cleanWhitespace = (text: string): string => {
  // Use regular expression to match one or more whitespace characters
  const cleanedString = text.replace(/\s+/g, ' ');
  return cleanedString;
};

/**
 * Truncates a string to a maximum of 32 characters and adds an ellipsis if the string is longer.
 *
 * @param str - The input string to be truncated.
 * @param maxLength - The max character.
 * @returns The truncated string with an ellipsis if the original string was longer than 32 characters.
 */
export const truncateWithEllipsis = (str: string, maxLength: number = 32): string => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + '...';
  } else {
    return str;
  }
};

/**
 * Joins an array of strings into a single string separated by commas.
 *
 * @param strings - An array of strings to be joined.
 * @returns A string where the elements of the input array are separated by commas.
 */
export const separateArrayByComma = (strings: string[]): string => {
  return strings.join(', ');
};

/**
 * Removes any leading or trailing whitespace from the input string.
 *
 * @param input - The input string to be trimmed.
 * @returns The trimmed string.
 */
const trimInput = (input: string) => input.trim();

/**
 * Replaces non-alphanumeric characters (except spaces) with a single underscore in the input string.
 *
 * @param input - The input string to be transformed.
 * @returns The transformed string with non-alphanumeric characters (except spaces) replaced by underscores.
 */
const transformToNonAlphanumeric = (input: string) => input.replace(/\[^a-zA-Z0-9\\s\]/g, '_');

/**
 * Converts the input string to uppercase.
 *
 * @param input - The input string to be converted.
 * @returns The uppercase string.
 */
const convertToUpperCase = (input: string) => input.toUpperCase();

/**
 * Replaces spaces in the input string with underscores.
 *
 * @param input - The input string to be transformed.
 * @returns The transformed string with spaces replaced by underscores.
 */
const replaceSpacesWithUnderscores = (input: string) => input.replace(/ /g, '_');

/**
 * Replaces multiple consecutive underscores in the input string with a single underscore.
 *
 * @param input - The input string to be transformed.
 * @returns The transformed string with multiple consecutive underscores replaced by a single underscore.
 */
const replaceMultipleToSingleUnderscore = (input: string) => input.replace(/\_+/g, '_');

/**
 * Removes any special characters or underscores at the end of the input string.
 *
 * @param input - The input string to be transformed.
 * @returns The transformed string with any special characters or underscores removed from the end.
 */
const removeSpecialCharactersFromEnd = (input: string) => input.replace(/\[\_-\]+$/g, '');

/**
 * Transforms a given string by performing the following operations:
 * - Removes leading and trailing spaces
 * - Replaces non-alphanumeric characters (except spaces) with underscores
 * - Converts the string to uppercase
 * - Replaces spaces with underscores
 * - Replaces multiple consecutive underscores with a single underscore
 * - Removes any special characters (underscores or hyphens) at the end of the string
 *
 * @param input - The input string to be transformed
 * @returns The transformed string
 */
export const transformStringToCapitalizedSnakeCase = (input: string): string => {
  // Remove any leading/trailing spaces
  let transformed = trimInput(input);
  transformed = transformToNonAlphanumeric(transformed);

  // Convert to uppercase
  transformed = convertToUpperCase(transformed);

  // Replace spaces with underscores
  transformed = replaceSpacesWithUnderscores(transformed);

  // Replace multiple underscores with a single underscore
  transformed = replaceMultipleToSingleUnderscore(transformed);

  // Remove special characters at the end of the sequence
  transformed = removeSpecialCharactersFromEnd(transformed);

  return transformed;
};

export function formatToTitleCase(input: string): string {
  return input
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
