import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IRequestCrewAppliedJobList,
  IRequestCrewProcessedList,
  IRequestPatchCrewProcessed,
  IRequestRejectCrew,
  IResponseCrewProcessedList,
  IReturnCrewAppliedJobList,
  IReturnCrewProcessedList,
  IReturnPatchCrewProcessed,
} from './type';
import { PAGINATION_LIMIT } from '@/constants/pagination';

export const talentHubApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'talentHubApi',
  tagTypes: ['CREW_PROCESSED', 'CREW_APPLIED'],
  endpoints: builder => ({
    getCrewProcessedList: builder.query<IReturnCrewProcessedList, IRequestCrewProcessedList>({
      query: payload => ({
        url: '/v1/company/talent-hub/crew/processed',
        method: 'GET',
        params: {
          page: payload.page,
          limit: PAGINATION_LIMIT,
          keyword: payload.keyword,
          status: payload.status,
          rank: payload.rank,
          vesselType: payload.vesselType,
          acquiredVia: payload.acquiredVia,
        },
      }),
      transformResponse: (response: IResponseCrewProcessedList) => ({
        list: response.items || [],
        pagination: response.meta,
      }),
      providesTags: ['CREW_PROCESSED'],
    }),
    rejectCrew: builder.mutation<void, IRequestRejectCrew>({
      query: payload => ({
        url: `/v1/company/job/${payload.jobId}/crew/${payload.crewId}/reject`,
        method: 'PATCH',
        body: {
          source: payload.source,
          rejectReasons: payload.rejectReasons,
          currentStatus: payload.currentStatus,
        },
      }),
      invalidatesTags: ['CREW_PROCESSED'],
    }),
    getCrewAppliedJobList: builder.query<IReturnCrewAppliedJobList, IRequestCrewAppliedJobList>({
      query: payload => ({
        url: `/v1/company/crew/${payload.crewId}/applied-job`,
        method: 'GET',
      }),
      providesTags: ['CREW_APPLIED'],
    }),
    patchCrewProcessed: builder.mutation<IReturnPatchCrewProcessed, IRequestPatchCrewProcessed>({
      query: ({ id, ...body }) => ({
        url: `/v1/company/talent-hub/crew/processed/${id}/next-step`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['CREW_PROCESSED'],
    }),
  }),
});

export const {
  useGetCrewProcessedListQuery,
  useRejectCrewMutation,
  useGetCrewAppliedJobListQuery,
  usePatchCrewProcessedMutation,
} = talentHubApi;
